<!--
 * @Autor: syq
 * @Date: 2021-07-14 10:29:44
-->
<template>
  <div id="remessage">
    <div class="collar-title">取消订单</div>
    <div class="collar-desc" @click="aaa">请选择原因</div>
    <div class="choose">
      <van-radio-group v-model="result">
        <van-cell-group>
          <van-cell
            :title="item"
            clickable
            @click="result = index + 1 + ''"
            v-for="(item, index) in selection"
            :key="index"
          >
            <template #right-icon>
              <van-radio :name="index + 1 + ''" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>
    <div class="quit">
      <van-button
        type="info"
        class="btn_info"
        @click="() => remove(true)"
        round
        block
        >确认</van-button
      >
    </div>
  </div>
</template>

<script>
import { cancelOrderBusinessDetail } from "../service";

export default {
  props: { isshow: Boolean },
  data() {
    return {
      close: false,
      show: false,
      selection: [
        "买多了",
        "商品规格下单有误",
        "信息填写错误",
        "未使用优惠",
        "需要更换其他商品",
        "其他",
      ],
      result: "",
    };
  },
  methods: {
    aaa() {
    },
    async remove() {
      await cancelOrderBusinessDetail(this.$route.query.orderNo).then(
        (respones) => {
          this.detailslist = respones.data;
        }
      );
      this.$parent.$parent.childShow();
    },
  },
};
</script>

<style lang="less">
#remessage {
  width: 100%;
  line-height: 20px;
  background-color: #fff;
  border-radius: 10px 10px 0 0;
  .collar-title {
    text-align: center;
    padding: 10px 15px;
    font-weight: bolder;
  }
  .collar-desc {
    margin-left: 15px;
    font-size: 12px;
    color: #999;
    padding: 5px 0;
  }
  .choose {
    margin-top: 5px;
  }
  .quit {
    padding: 12px 15px;
    padding-bottom: 0;
  }

  .btn_info {
    background: #0095da;
    border: none;
  }

  .van-radio__icon--checked .van-icon {
    background: #0095da !important;
    border: none;
  }
  /deep/ .van-radio__icon .van-radio__icon--round .van-radio__icon--checked {
    background: #0095da !important;
    border: none;
  }

  /deep/ .van-checkbox__icon--checked .van-icon {
    background: #0095da;
    border: none;
  }
}
</style>
