<template>
  <div id="orderdetails">
    <div>
      <div class="order-status">
        <div class="order-status-content">
          <div class="order-status-left">
            <p>{{status.get(detailslist.tradeStatus)}}</p>
            <!-- detailslist.tradeStatus == 45 || -->
            <div
              class="time"
              v-if="(detailslist.tradeStatus == 5 ||detailslist.tradeStatus == 45)&& (typeof(detailslist.day) !== 'undefined')"
            >
              <Time :data="detailslist" />
            </div>
          </div>
          <div class="order-status-right">
            <img :src="boxImg" alt="" />
          </div>
        </div>
      </div>

      <Address :data="detailslist" />

      <div class="order-floor">
        <span class="order-img">
          <img :src="findlogo(detailslist)" alt="" />
        </span>
        <span class="order-name">{{ detailslist.storeName }}</span>
      </div>

      <div class="order-list">
        <Order :data="detailslist" />
      </div>

      <div class="order-info">
        <p class="total-details">金额明细</p>
        <van-cell title="商品总额" :value="detailslist.orderAmount" />
        <van-cell
          title="活动优惠"
          :value="detailslist.promotionDiscountAmount"
        />
        <!-- <van-cell title="优惠券" :value="detailslist.discountAmount" /> -->
        <van-cell title="配送费用" :value="detailslist.freightAmount" />
        <p class="total-money">
          <span class="total-order">订单总额：</span>
          <span class="money">¥{{ detailslist.payableAmount }}</span>
        </p>
      </div>

      <div class="order-info">
        <p class="total-details">订单明细</p>
        <van-cell title="订单编号" :value="detailslist.orderNo" />
        <van-cell title="下单时间" :value="detailslist.createTime" />
        <van-cell title="支付时间" v-show="show" value="2012-10-25 03:08:23" />
        <van-cell title="支付方式" v-show="show" value="微信支付" />
      </div>

      <div class="footer">
        <div class="footer-service" @click.stop="poPKefu">
          <van-icon name="service-o" />
          <span>客服</span>
        </div>
        <div class="footer-btn">
          <div class="footer-btn-item" v-show="detailslist.tradeStatus === 5">
            <van-button
              plain
              type="default"
              size="small"
              round
              class="info_btn_qu"
              @click="() => remove()"
              >取消订单</van-button
            >
          </div>
           <div class="footer-btn-item" v-if="detailslist.tradeStatus === 44 || detailslist.tradeStatus === 45 || detailslist.tradeStatus === 0 ">
            <van-button
              plain
              type="default"
              size="small"
              round
              class="info_btn_qu"
              @click="()=>logisitics(detailslist)"
              >发货信息查询</van-button
            >
          </div>
          <div
            class="footer-btn-item"
            v-show="detailslist.tradeStatus === 5"
            @click="() => watchpaymsg(detailslist)"
          >
            <van-button type="info" class="info_btn" size="small" round
              >查看打款信息</van-button
            >
          </div>
          <!-- <div class="footer-btn-item" v-if="detailslist.tradeStatus === 5">
            <van-button type="info" class="info_btn" size="small" round
              >立即支付</van-button
            >
          </div> -->
        </div>
        <div>
          <!--          <van-grid>-->
          <!--            <van-grid-item icon="service" text="客服" @click="poPKefu" />-->
          <!--          </van-grid>-->
          <!--        </div>-->
          <!--        <div class="footer-btn">-->
          <!--          <button-->
          <!--            class="order-btn"-->
          <!--            @click="() => remove()"-->
          <!--            v-if="detailslist.tradeStatus === 5"-->
          <!--          >-->
          <!--            取消订单-->
          <!--          </button>-->
          <!--          <button class="pay-btn" v-show="detailslist.tradeStatus !== -1">-->
          <!--            立即支付-->
          <!--          </button>-->
        </div>
      </div>

      <van-dialog v-model="ordershow" :showCancelButton="false">
        <div class="order-paymessage">
          <p>
            请向以下商家汇款<span>
              ￥{{
                (paymessagelist.totalActualAmount || 0).toFixed(2)
              }}&nbsp;</span
            >用于支付货款
          </p>
          <div
            class="paymessage-name"
            v-for="(payitem, index) in paybank"
            :key="index"
          >
            <p>
              户名：<span>{{ payitem.bank.name }}</span>
            </p>
            <p>
              开户行：<span> {{ payitem.bank.bankName }}</span>
            </p>
            <p>
              账户：<span> {{ payitem.bank.bankAccount }}</span>
            </p>
          </div>
        </div>
      </van-dialog>
      <van-popup v-model="show" position="bottom" class="popup">
        <Remessage :childShow="childShow" />
      </van-popup>
    </div>
    <van-loading size="24px" v-show="orderdetail" vertical
      >加载中...</van-loading
    >
  </div>
</template>

<script>
import boxImg from "../../assets/icon/box@2x.png";
import Remessage from "./components/remessage.vue";
import Time from "./components/time.vue";
import Order from "./components/card.vue";
import Address from "./components/address.vue";
import Header from "../../components/Header.vue";
import { handleCommonTimeRender } from "../../utils/utils.js";
import { getOrderBusinessDetail, getPaymentinformation } from "./service";
import { Toast } from "vant";
export default {
  components: { Time, Order, Address, Remessage, Header },
  data() {
    return {
      boxImg,
      show: false,
      ordershow: false,
      showCancelButton: false,
      orderdetail: false,
      detailslist: {},
      paymessagelist: {},
      paybank: [],
      status: new Map([
        [5, "待付款"],
        [25, "待审核"],
        [40, "待发货"],
        [44, "部分发货"],
        [45, "待收货"],
        [0, "交易完成"],
        [-1, "交易取消"],
      ]),
    };
  },
  mounted() {
    this.goodsdetails();
  },
  methods: {
    logisitics(item){
      this.$router.push({path:'/logistics',query:{
        orderNo:item.orderNo
      }})
    },
    //店铺logo
    findlogo(item){
      return item?.ext ? JSON.parse(JSON.parse(item.ext).logo)[0]?.thumbUrl : 'http://www.liby.com.cn/images/logo.png'
    },
    poPKefu() {
      window.kefuButton.click();
    },
    goodsdetails() {
      this.orderdetail = true;
      getOrderBusinessDetail(
        this.$route.query.orderNo,
        this.$route.query.isDetail
      )
        .then((res) => {
          if (res.status === "0") {
            this.orderdetail = false;
            this.detailslist = res.data;
            this.detailslist.createTime = handleCommonTimeRender(
              this.detailslist.createTime
            );
          } else {
            this.orderdetail = false;
            Toast.fail(res.msg);
          }
        })
        .catch((error) => {
          this.orderdetail = false;
          Toast.fail(error);
        });
    },
    watchpaymsg(item) {
      this.ordershow = true;
      getPaymentinformation(item.parentOrderNo)
        .then((res) => {
          if (res.status === "0") {
            this.paymessagelist = res.data;
            this.paybank = res?.data?.orderList.filter(
              (k) => k.storeCode === item.storeCode
            );
            // this.ordershow = false;
          } else {
            Toast(res.msg);
          }
        })
        .catch((error) => {
          this.ordershow = false;
          Toast.fail(error);
        });
    },
    poPKefu() {
      window.kefuButton.click();
    },
    remove() {
      this.show = true;
    },
    childShow() {
      this.show = false;
      Toast("取消成功");
      this.$router.push("/mineorder");
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");
</style>
<style lang="less" scoped>
#orderdetails {
  background-color: @BackgroundColor;
  .order-status {
    width: 100%;
    padding: 12px 12px 0 12px;
    .order-status-content{
      width: 100%;
      padding: 15px;
      display: flex;
      border-radius: 8px;
      background-color: #fff;
      .order-status-left {
        flex: 1;
        p{
          margin: 0;
          width: auto;
          height: 20px;
          line-height: 20px;
          font-style: 14px;
          font-weight: 500;
          font-family: PingFangSC, PingFangSC-Medium;
        }
        .time{
          margin-top: 8px;
        }

      }
      .order-status-right {
        width: 78px;
        height: 66px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

  }
  .order-floor {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 8px 10px;
    margin: 0 12px;
    border-radius: 8px 8px 0 0;
    .order-img {
      padding-right: 10px;
      font-size: 0;
      img {
        width: 24px;
        height: 24px;
      }
    }
    .order-name {
      flex: 1;
      color: #000;
      font-weight: bolder;
      font-size: 14px;
    }
  }
  .order-list {
    background: #fff;
    margin: 0 12px;
    border-radius: 0 0 8px 8px;
    overflow: hidden;
  }
  .order-info {
    background: #fff;
    margin: 8px 12px;
    border-radius: 8px;
    overflow: hidden;
    .total-details {
      font-family: "Arial-BoldMT", "Arial Bold", "Arial", sans-serif;
      font-size: 14px;
      font-weight: bolder;
      margin: 0;
      padding: 12px 15px;
    }

    .total-money {
      height: 54px;
      text-align: right;
      line-height: 54px;
      padding-right: 15px;
      margin: 0;
    }
    .total-order {
      font-family: "Arial Normal", "Arial", sans-serif;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      position: relative;
      left: 5px;
    }
    .money {
      font-size: 18px;
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      color: #ff3228;
    }
  }
  .van-cell {
    font-size: 13px !important;
    font-family: "微软雅黑", sans-serif;
    font-weight: 400 !important;
    font-style: normal;
    text-align: left;
    color: #333333 !important;
  }
  .van-cell__value {
    color: #333333 !important;
  }
  .footer {
    background: #fff;
    text-align: center;
    padding: 10px 15px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    .footer-service {
      //
      text-align: center;
      display: inline-block;
      width: 40px;
      line-height: 20px;
      font-size: 18px;
      color: #666;
      span {
        display: block;
        color: #999;
        font-size: 12px;
        height: 18px;
        line-height: 18px;
      }
    }
    .footer-btn {
      flex: 1;
      //width: calc(100% - 40px);
      display: flex;
      align-items: center;
      justify-content: flex-end;
      text-align: right;
      .footer-btn-item {
        margin-left: 8px;
      }
    }
  }
}
/deep/.van-dialog {
  padding-left: 20px !important;
  border-radius: 8px !important;
  font-size: 15px !important;
  .order-paymessage {
    color: #aaaaaa;
    p span {
      color: #d9001b;
    }
  }
  .paymessage-name p span {
    color: #f5a804;
  }
}
.info_btn {
  border-radius: 8px;
  background: #0095da;
  border: none;
  height: 32px;
  padding: 0 8px;
}

.info_btn_qu {
  border-radius: 8px;
  border: 1px solid #0095da;
  color: #0095da;
  height: 32px;
  padding: 0 8px;
}

.popup {
  height: 57%;
  border-radius: 10px 10px 0 0;
}
.van-cell__value {
  span {
    font-family: "Montserrat", sans-serif;
  }
}
</style>
