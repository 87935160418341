<!--
 * @Autor: syq
 * @Date: 2021-07-22 21:31:53
-->
<template>
  <div id="card">
    <div v-for="(item, index) in data.orderBusinessLineList" :key="index">
      <van-card
        @click="shopdetails(item)"
        :num="item.skuQuantity"
        :price="item.actualPrice.toFixed(2)"
        :desc="item.skuSaleProperty"
        :title="item.itemName"
        :thumb="item.mediaList[0].url"
      >
        <template #tag v-if="item.skuType === 30">
          <img src="" alt="" />
        </template>
        <template #origin-price>
          <span>{{ findPrice(item) }}</span>
        </template>
      </van-card>
      <div class="card-btn" v-if="tradeStatus.includes(data.tradeStatus)">
        <!-- <van-button plain type="default" size="small" round @click="market"
          >申请售后</van-button
        > -->
      </div>
      <!--       <button class="card-btn" @click="market">申请售后</button>-->
    </div>
  </div>
</template>

<script>
export default {
  props: { data: Object },
  data() {
    return {
      tradeStatus: [40, 44, 45, 0],
    };
  },
  mounted() {},
  methods: {
    findPrice(item) {
      if (item.skuType === 30) {
        return "";
      } else if (item.skuType !== 30 && item.discountAmount !== 0) {
        return `￥${item.originPrice.toFixed(2)}`;
      } else {
        return "";
      }
    },
    market() {
      this.$router.push("/aftermarket");
    },
    shopdetails(item) {
      const { itemCode, storeCode } = item;
      this.$router.push({
        path: "/shopdetails",
        query: { itemCode: itemCode, storeCode: storeCode },
      });
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");
</style>
<style lang="less">
#card {
  position: relative;
  .van-card {
    background-color: rgba(255, 255, 255, 1);
    position: relative;
  }
  .van-card::after {
    position: absolute;
    z-index: 3;
    right: 0;
    bottom: 0;
    left: 15px;
    height: 1px;
    content: "";
    -webkit-transform: scaleY(0.5);
    transform: scaleY(0.5);
    background-color: #e6e6e6;
  }
  .van-card__title {
    font-family: "ArialMT", "Arial", sans-serif !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    color: #333333 !important;
  }
  .van-card__desc {
    line-height: 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 11px !important;
    color: #aaaaaa !important;
  }
  .van-card__price {
    font-size: 16px !important;
    color: #ff2731 !important;
  }
  .van-card__footer {
    width: 58px !important;
    height: 30px !important;
    font-family: "微软雅黑 Regular", "微软雅黑", sans-serif;
    font-weight: 400 !important;
    font-size: 12px !important;
    background-color: rgba(255, 255, 255, 0);
    .btn {
      color: #333333;
      border-radius: 50px;
      border: 1px solid rgba(228, 228, 228, 1);
    }
  }
  .card-btn {
    position: absolute;
    bottom: 34px;
    right: 16px;
  }

  .van-card__price {
    font-family: "Montserrat", sans-serif;
  }
  .van-card__num {
    font-family: "Montserrat", sans-serif;
  }

  .van-card__price-integer {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 16px;
  }
  .van-card__price-decimal {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 600;
  }
  .van-card__price-currency {
    font-weight: 600;
  }
}
</style>
