<!--
 * @Autor: syq
 * @Date: 2021-07-12 19:23:20
-->
<template>
  <div id="timetime">
    <!-- <div class="h"></div> -->
    <div id="time">
      <div class="order-time">
        <van-count-down class="timeColor" :time="getDateTime" @finish="finish">
          <template #default="timeData">
            <span class="block">{{ timeData.days }}</span>
            <span class="colon">天</span>
            <span class="block">{{ timeData.hours }}</span>
            <span class="colon">时</span>
            <span class="block">{{ timeData.minutes }}</span>
            <span class="colon">分</span>
            <span class="block">{{ timeData.seconds }}</span>
            <span class="colon">秒</span>
            <span class="back"
              >{{ data.tradeStatus == 45 ? "后自动收货" : "后自动取消订单" }}
            </span>
          </template>
        </van-count-down>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { data: Object },

  data() {
    return {
      time: "",
    };
  },
  mounted() {
    this.time = "";
  },
  computed: {
    getDateTime() {
      return (
        parseInt(this.data.day) * 3600 * 24 * 1000 +
        parseInt(this.data.hour) * 3600 * 1000 +
        parseInt(this.data.minutes) * 60 * 1000 +
        parseInt(this.data.seconds) * 1000
      );
    },
  },
  methods: {
    finish() {
      this.$parent.goodsdetails();
      this.$router.push("/mineorder");
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
</style>
<style lang="less" scoped>
#timetime {
  #time {
    /deep/ .order-time {
      .van-count-down {
        text-align: left;
        line-height: 15px;
        font-weight: 500 ;
        font-size: 12px;
        color: #1c2529;
      }
    }
    .back {
       color: #1c2529;
    }
  }
  .block {
    font-family: "Montserrat", sans-serif;
  }
}
</style>
