<!--
 * @Author: your name
 * @Date: 2021-07-15 09:27:09
 * @LastEditTime: 2021-07-21 20:54:11
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \marketing-channel-platform-front-h5\src\components\Header.vue
-->
<template>
  <div class="tab-header">
    <van-nav-bar title="营销通路平台" @click-left="onClickLeft" left-arrow>
    </van-nav-bar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "",
    };
  },
  methods: {
    onClickLeft() {
      this.$router.push("/home");
    },
  },
};
</script>

<style lang="less" scoped>
.tab-header {
  /deep/.van-nav-bar__title {
    font-size: 20px;
    font-weight: 600;
  }
}
</style>
