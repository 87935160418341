<!--
 * @Autor: syq
 * @Date: 2021-07-12 19:23:20
-->
<template>
  <div id="address">
    <div class="coupon-border">
      <div class="coupon-content">
        <div class="coupon-head">
          <img class="icon" src="../../../assets/icon/address_icon@3x.png" />
        </div>
        <div class="coupon-body">
          <p class="coupon-address">{{ data.address && data.address.detailAddress }}</p>
          <p class="coupon-time">
            <span class="coupon-name">{{ data.address && data.address.receiver }}</span>
            <span class="coupon-phone">{{ data.address && data.address.phoneNumber }}</span>
          </p>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
export default {
  props: { data: Object },
  data() {
    return {
      couponlist: [
        {
          address: "南山区科苑路15号科兴科学园",
          name: "苏小萌  小姐  ",
          phone: "180****8888",
        },
      ],
    };
  },
  computed: {},
  methods: {
    add() {
      this.$router.push("/addressAdd");
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");
</style>
<style lang="less">
#address {
  margin: 8px 12px;
  background-color: #fff;
  border-radius: 8px;
  .coupon-border {
    .coupon-content {
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      align-items: center;
      box-sizing: border-box;
      min-height: 75px;
      color: #666666;
      padding: 5px 10px;
    }
    .coupon-head {
      font-size: 0;
      padding-right: 10px;
      .icon {
        width: 32px;
        height: 32px;
      }
    }
    .coupon-body {
      position: relative;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      flex: 1;
      border-radius: 0 8px 8px 0;
      .coupon-address {
        margin: 0;
        padding-bottom: 5px;
        font-family: "微软雅黑", sans-serif;
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
        color: #333;
      }
      .coupon-time {
        font-size: 13px;
        padding: 0;
        margin: 0;
        .coupon-name {
          color: #333;
        }
        .coupon-phone {
          padding-left: 20px;
          color: #999;
          font-family: "Montserrat", sans-serif;
        }
      }
      .coupon-btn {
        position: absolute;
        left: 210px;
        top: 30px;
      }
    }
  }
}
</style>
