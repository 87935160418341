/*
 * @Autor: syq
 * @Date: 2021-07-12 16:37:32
 */

import request from "../../request/request";

// 获取前端类目树父节点集合
//订单列表
const getOrderBusinessDetail = async (params) =>
  request({
    url: `/orderBusiness/getOrderBusinessDetail?isDetail=true&qp-orderNo-eq=${params}`,
    method: "get",
  });
const cancelOrderBusinessDetail = async (params) =>
  request({
    url: `/cartLine/cancelOrder?event=-1&orderNo=${params}`,
    method: "post",
  });
  const getPaymentinformation = async (id) =>
  request({
    url: `/orderBusiness/getParentOrderBusiness?qp-parentOrderNo-eq=${id}`,
    method: "get",
  });
export { getOrderBusinessDetail, cancelOrderBusinessDetail,getPaymentinformation };
